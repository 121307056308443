<template>
  <div class="">
    <el-form class="demo-form-inline" :inline="true" size="mini">
      <el-form-item label="">
        <el-input v-model="search.keywords" placeholder="请输入名称" size="small" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="small" @click="handleSearch()">搜索</el-button>
      </el-form-item>
    </el-form>
    <el-table
        :data="tableData"
        @selection-change="selectNews"
        ref="rfTable"
        style="width: 100%;"
        size="mini">
      <el-table-column v-if="type == 2"
         type="selection"
         align="center"
         width="55">
      </el-table-column>
      <el-table-column
          prop="id"
          label="ID"
          width="100">
      </el-table-column>
      <el-table-column
          prop="title"
          label="优惠券名称"
          width="160">
      </el-table-column>
      <el-table-column
          prop="time_type"
          label="有效期范围"
          align="left"
          width="230">
        <template slot-scope="scope">
          <span v-if="scope.row.time_type==2">{{ scope.row.start_time }} ~ {{ scope.row.end_time }}</span>
          <span v-if="scope.row.time_type==1">领取{{ scope.row.start_day }}天后生效 ~ 领取{{ scope.row.end_day }}天后失效</span>
        </template>
      </el-table-column>
      <el-table-column
          prop="time_type"
          label="生效条件"
          align="left"
          width="110">
        <template slot-scope="scope">
          <span v-if="scope.row.is_condition==0">无条件</span>
          <span v-if="scope.row.is_condition==1">满{{ scope.row.condition_val }}元</span>
        </template>
      </el-table-column>
      <el-table-column
          prop="time_type"
          label="优惠内容"
          align="left"
          width="110">
        <template slot-scope="scope">
          <span v-if="scope.row.coupon_type==1">减{{ scope.row.coupon_val }}元</span>
          <span v-if="scope.row.coupon_type==2">打{{ scope.row.coupon_val / 10 }}折</span>
          <span v-if="scope.row.coupon_type==3">兑：{{ scope.row.goods_name }}</span>
        </template>
      </el-table-column>
      <el-table-column
          prop="time_type"
          label="库存/总量"
          align="center"
          width="100">
        <template slot-scope="scope">
          {{ scope.row.sale_sum }} / {{ scope.row.sum }}
        </template>
      </el-table-column>
      <el-table-column
          prop="time_type"
          label="生效状态"
          align="center"
          width="120">
        <template slot-scope="scope">
          {{ scope.row.effect_status }}
        </template>
      </el-table-column>
      <el-table-column
          prop="ftime"
          label="添加时间"
          align="center"
          width="140">
      </el-table-column>
      <el-table-column v-if="type == 1"
         fixed="right"
         label="选择"
         align="center"
         width="100">
        <template slot-scope="scope">
          <el-button type="danger" size="small" @click="selectRow(scope.row)">选择</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        background
        @current-change="changePage"
        layout="total, prev, pager, next"
        :page-size="search.limit"
        :total="search.total">
    </el-pagination>
    <el-button type="primary" size="small" @click="chooseOk()" style="position: absolute; bottom: 35px; left: 20px;" v-if="type == 2">确定</el-button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      search: {
        current_page: 1,
        total: 0,
        limit: 5,
        keywords: ''
      },
      tableData: [],
      chooseList: []
    }
  },
  components: {},
  props: {
    callFunc: Function,
    idx: Number,
    type: Number, // 1-单选，2-多选
    sendType: Number // 类型：0-全部，1-用户领取，2-系统发放
  },
  created() {
    this.getList();
  },
  mounted() {
  },
  methods: {
    getList() {
      var that = this
      that.showLoading()
      var param = {
        page: that.search.current_page,
        page_size: that.search.limit,
        keywords: that.search.keywords,
        send_type: this.sendType
      }
      this.$api.marking.couponIndex(param, function (res) {
        that.hideLoading()
        if (res.errcode == 0) {
          that.tableData = res.data.data
          that.search.current_page = res.data.current_page
          that.search.total = res.data.total
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    handleSearch() {
      this.search.current_page = 1
      this.getList()
    },
    changePage(page) {
      this.search.current_page = page
      this.getList()
    },
    selectRow(row) {
      this.callFunc(row)
    },
    selectNews(data) {
      this.chooseList = data
    },
    // 多选操作
    chooseOk() {
      if (this.chooseList.length < 1) {
        this.fail('请选择会员卡')
      } else {
        this.callFunc(this.chooseList)
      }
    }
  }
}
</script>

<style scoped>
</style>
