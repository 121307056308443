<template>
  <div class="box">
    <el-form :model="info" label-position="right" :rules="rules" ref="ruleForm" label-width="100px" class="ruleForm" size="small">
      <el-form-item label="充值金额" prop="amount">
        <el-input v-model="info.amount" placeholder="请输入金额">
          <template slot="append">元</template>
        </el-input>
      </el-form-item>
      <el-form-item label="赠送金额" prop="send_amount">
        <el-input v-model="info.send_amount" placeholder="请输入金额">
          <template slot="append">元</template>
        </el-input>
      </el-form-item>
      <el-form-item label="赠送积分" prop="send_integral">
        <el-input v-model="info.send_integral" placeholder="请输入积分">
          <template slot="append">积分</template>
        </el-input>
      </el-form-item>
      <el-form-item label="赠送优惠券">
        <el-button type="text" @click="showModal=true">选择优惠券</el-button>
        <div class="list">
          <div class="item mg-tp-10" v-for="(item,index) in info.coupon" :key="index">
            <el-row :gutter="10">
              <el-col :span="6">
                {{ item.title }}
              </el-col>
              <el-col :span="5">
                <el-input-number v-model="item.sum" placeholder="请输入数量" :min="1" :max="10"></el-input-number>
              </el-col>
              <el-col :span="2">
                <el-button type="text" @click="delCoupon(index)">删除</el-button>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
        <el-button type="button" @click="reBack()">返回</el-button>
      </el-form-item>
    </el-form>
    <el-dialog
        title="选择券"
        :visible.sync="showModal"
        :destroy-on-close="true"
        width="900px">
      <coupon-list :type="2" :sendType="0" :callFunc="setCoupon"></coupon-list>
    </el-dialog>
  </div>
</template>
<script>

import CouponList from "@/components/Marking/CouponList";
export default {
  data() {
    return {
      showModal: false,
      info: {
        id: 0,
        amount: "",
        send_amount: "",
        send_integral: "",
        coupon: []
      },
      source_domain: this.config.SOURCE_DOMAIN,
      rules: {
        amount: [
          { required: true, message: '请输入充值金额', trigger: 'blur' }
        ]
      }
    };
  },
  components: {
    CouponList
  },
  created() {
    if(this.$route.query.id) {
      this.info.id = this.$route.query.id
      this.getInfo()
    }
  },
  mounted() {
  },
  computed: {
  },
  methods: {
    setCoupon(coupon) {
      var _this = this
      coupon.forEach(item => {
        var isTrue = false
        this.info.coupon.forEach(child => {
          if(child.id==item.id) {
            isTrue = true
          }
        })
        if(!isTrue) {
          _this.info.coupon.push({id:item.id,title:item.title,sum:1})
        }
      })
      this.showModal = false
    },
    delCoupon(idx) {
      this.info.coupon.splice(idx,1)
    },
    getInfo() {
      this.showLoading()
      this.$api.marking.rechargeInfo({id: this.info.id}, res => {
        this.hideLoading()
        if(res.errcode == 0) {
          this.info = res.data
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    submitForm(formName) {
      var that = this
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var param = that.info
          this.$api.marking.rechargeEdit(param,function (res) {
            if(res.errcode == 0) {
              that.success(res.errmsg)
              that.reBack()
            } else {
              that.fail(res.errmsg)
            }
          })
        } else {
          return false;
        }
      });
    }
  }
};
</script>
<style scoped>
  .box {
    background-color: #fff;
    padding: 20px;
    border-radius: 6px;
    min-height: 500px;
  }
  .ruleForm {
    width: 800px;
  }
</style>
